import './modules/bootstrap';
import axios from 'axios';
import copy from 'copy-to-clipboard';

import '../scss/styles.scss';

const inputShareUrl = document.querySelector('input.share-url');
const inputDownloadUrl = document.querySelector('input.download-url');
const btnDownload = document.querySelector('.btn.download');
const btnSubmit = document.querySelector('.btn.submit');
const btnClose = document.querySelector('.btn.close');
const spinner = document.querySelector('.process');
const downLoadArea = document.querySelector('.download-area');
const errorBox = document.querySelector('.error');
const errorMessage = errorBox.querySelector('.message');
const btnCopy = document.querySelector('.btn.copy');
const iconCopy = btnCopy.querySelector('.icon.copy');
const iconSpinner = btnCopy.querySelector('.icon.spinner');
const iconOk = btnCopy.querySelector('.icon.ok');
const iconError = btnCopy.querySelector('.icon.error');

// toggle submit button
inputShareUrl.addEventListener('input', function () {
  if (this.value.trim() !== '') {
    // enable submit button if input is not blank
    btnSubmit.disabled = false;
  } else {
    // disable submit button if input is blank
    btnSubmit.disabled = true;
  }
});

// set url input focus
inputShareUrl.addEventListener('focus', () => {
  inputShareUrl.select();
});

// send "generate" post request
inputShareUrl.addEventListener('keypress', event => {
  if (event.key === 'Enter') {
    btnSubmit.click();
  }
});
btnSubmit.addEventListener('click', () => {
  generateRequest();
});
const generateRequest = async () => {
  hideDownloadArea();
  showSpinner();
  try {
    const res = await axios.post(
      variables.generate,
      {
        url: inputShareUrl.value
      },
      {
        // this header is required by CodeIgniter to handle JSON
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }
    );
    hideSpinner();
    showDownloadArea(res.data);
  } catch (error) {
    hideSpinner();
    console.log(error);
    // if response is not OK
    if (error.response.status === 0) {
      // 1. "error.request": the request was made but no response was received
      // 2. something happened in setting up the request that triggered an Error
      // 3. network error request could not be sent
      showError(variables.locales.networkError);
    } else {
      // Server responded with a status code !== 200
      //   showError(error.response.data.message);
      showError(error.response.data);
    }
  }
};

// copy download link to clipboard
btnCopy.addEventListener('click', () => {
  const success = copy(inputDownloadUrl.value);
  inputDownloadUrl.select();
  iconCopy.style.display = 'none';
  iconSpinner.style.display = 'block';
  setTimeout(() => {
    iconSpinner.style.display = 'none';
    if (success) iconOk.style.display = 'block';
    else iconError.style.display = 'block';
    setTimeout(() => {
      if (success) iconOk.style.display = 'none';
      else iconError.style.display = 'none';
      iconCopy.style.display = 'block';
    }, 1000);
  }, 1500);
});

// some helper functions
const showSpinner = () => {
  spinner.classList.add('show');
};
const hideSpinner = () => {
  spinner.classList.remove('show');
};

const showDownloadArea = downloadLink => {
  spinner.scrollIntoView({ behavior: 'smooth', block: 'center' })
  btnDownload.setAttribute('href', downloadLink);
  inputDownloadUrl.value = downloadLink;
  downLoadArea.classList.add('show');
};
const hideDownloadArea = () => {
  inputDownloadUrl.value = '';
  downLoadArea.classList.remove('show');
};

const showError = message => {
  errorMessage.textContent = message;
  errorBox.classList.add('show');
  setTimeout(() => {
    hideError();
  }, 5 * 1000);
};
const hideError = () => {
  errorMessage.textContent = '';
  errorBox.classList.remove('show');
};

btnClose.addEventListener('click', () => {
  hideError();
});
